<template>
  <div class="about-wrapper">
    <h3 class="about-title">Tentang Sarung Indonesia</h3>
    <hr class="line-content mt-4">
    <p class="mt-4">
      Sarung Indonesia adalah sebuah platform jual beli sarung pertama di Indonesia. Dengan hadirnya Sarung Indonesia diharapkan bisa menjadi wadah bag teman-teman yang ingin berjualan produk sarung dengan harga kompetetif.
    </p>
    <p class="mt-4">
      Sarung Indonesia menjual produk Sarung Indonesia dengan brand Sarung BHS dan Sarung Atlas. Selain produk sarung, Sarung Indonesia juga menjual kebutuhan produk muslim, seperti baju muslim, songkok, subaiyah, sprei dan kain batik. Tentunya produk di Sarung Indonesia tersedia berbagai macam kelas, motif, dan warna.
    </p>
    <p class="mt-4">
      Sarung Indonesia menjual produk Sarung Indonesia dengan brand Sarung BHS dan Sarung Atlas. Selain produk sarung, Sarung Indonesia juga menjual kebutuhan produk muslim, seperti baju muslim, songkok, subaiyah, sprei dan kain batik. Tentunya produk di Sarung Indonesia tersedia berbagai macam kelas, motif, dan warna.
    </p>

    <!-- <div class="about-swiper">
      <Swiper {...params}>
        <div class="swiper-content">
          <img src="/images/shopping-bag-circle.png" alt="swiper-icon" />
          <h4 class="swiper-title">PEMBELI BIASA</h4>
          <p>Anda bisa melakukan pembelian langsung di SKU yang Anda inginkan dengan menggunakan payment gateway dan ekpedisi yang sudah terintegrasi</p>
        </div>
        <div class="swiper-content">
          <img src="/images/shopping-bag-circle.png" alt="swiper-icon" />
          <h4 class="swiper-title">PEMBELI DROPSHIPER</h4>
          <p>Anda bisa mendapatkan potongan harga dan peluang untuk mendapatkan keuntungan penjualan ketika ada endaftar sebagai dropshiper</p>
        </div>
        <div class="swiper-content">
          <img src="/images/shopping-bag-circle.png" alt="swiper-icon" />
          <h4 class="swiper-title">PEMBELI RESELLER</h4>
          <p>Anda bisa mendapatkan potongan harga lebih besar lagi jika meregistrasikan diri sebagai reseller</p>
        </div>
      </Swiper>
    </div> -->

    <!-- <div class="dekstop-view mt-5">
      <div class="swiper-content">
        <img :src="require('@/resources/images/shopping-bag.png')" alt="swiper-icon-1" />
        <h4 class="swiper-title">PEMBELI BIASA</h4>
        <p>Anda bisa melakukan pembelian langsung di SKU yang Anda inginkan dengan menggunakan payment gateway dan ekpedisi yang sudah terintegrasi</p>
      </div>
      <div class="swiper-content">
        <img :src="require('@/resources/images/box.png')" alt="swiper-icon-2" />
        <h4 class="swiper-title">PEMBELI DROPSHIPER</h4>
        <p>Anda bisa mendapatkan potongan harga dan peluang untuk mendapatkan keuntungan penjualan ketika ada endaftar sebagai dropshiper</p>
      </div>
      <div class="swiper-content">
        <img :src="require('@/resources/images/label.png')" alt="swiper-icon-3" />
        <h4 class="swiper-title">PEMBELI RESELLER</h4>
        <p>Anda bisa mendapatkan potongan harga lebih besar lagi jika meregistrasikan diri sebagai reseller</p>
      </div>
    </div> -->

    <!-- <div class="register-wrapper mt-5">
      <h3 class="register-title">
        MAU JADI RESELLER & MITRA KAMI?
      </h3>
      <p>
        Untuk menjadi reseller dan dropshiper segera lakukan registrasi
        dengan mengisi form pendaftaran di menu registrasi
      </p>
      <button class="about-btn-register mt-4">Daftar</button>
    </div> -->
  </div>
</template>
<script>

export default {
  name: 'component.about-bhs',
}
</script>

<style scoped lang='scss'>
.about-wrapper {
  width: 100%;
  margin: 0 auto;
}

.about-wrapper .about-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  color: var(--biz-primary-100);

  @media (max-width: 576px) {
    font-size: 18px;
    line-height: 20px;
  }
}

.about-wrapper p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: justify;
  color: var(--biz-primary-100);

  @media (max-width: 576px) {
    font-size: 12px;
    line-height: 20px;
  }
}

.register-wrapper {
  width: 100%;
}

.register-wrapper h3.register-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: var(--biz-primary-100);

  @media (max-width:576px) {
    font-size: 18px;
    line-height: 20px;
  }
}

.register-wrapper p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: justify;
  color: var(--biz-primary-100);

  @media (max-width: 576px) {
    margin-top: 15px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
}

.register-wrapper button.about-btn-register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 100%;
  height: 48px;
  background: var(--biz-primary-100);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #FFFFFF;
  border: none;

  @media (max-width: 576px) {
    font-size: 14px;
  }
}

.dekstop-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 576px) {
    flex-direction: column;
    gap: 15px;
  }

  // .swiper-content {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   padding: 16px 0;
  //   flex-direction: column;
  //   gap: 24px;
  //   background: #FFFFFF;
  //   border: 1px solid #F3F3F3;
  //   border-radius: 6px;
  //   width: 30%;

  //     @media (max-width: 567px) {
  //       width: 100%;
  //     }

  //     h4.swiper-title {
  //       font-family: 'Open Sans';
  //       font-style: normal;
  //       font-weight: 700;
  //       font-size: 14px;
  //       line-height: 18px;
  //       display: flex;
  //       align-items: center;
  //       text-align: justify;
  //       color: var(--biz-primary-100);
  //     }

  //     p {
  //       font-family: 'Open Sans';
  //       font-style: normal;
  //       font-weight: 400;
  //       font-size: 12px;
  //       line-height: 24px;
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       text-align: center;
  //       color: var(--biz-primary-100);
  //       width: 90%;
  //     }
  // }

}
</style>
