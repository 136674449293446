<template>
  <div class="about-wrapper-views">
    <About />
  </div>
</template>
<script>
import About from '@/components/About/About.vue'
export default {
  components: { About },
  name: 'about-bhs',
}
</script>
<style scoped lang='scss'>
.about-wrapper-views {
  width: 80%;
  margin: 0 auto;

  @media (max-width: 567px) {
    width: 95%;
  }
}
</style>
